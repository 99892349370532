import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 2560.000000 2560.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,2560.000000) scale(0.100000,-0.100000)">
          <path d="M14466 14944 c-82 -20 -187 -92 -241 -165 -67 -91 -80 -135 -80 -269
0 -108 2 -119 31 -180 58 -122 165 -212 289 -245 207 -54 418 41 511 230 170
344 -135 720 -510 629z"/>
<path d="M10512 12633 l3 -1978 485 0 485 0 3 648 2 648 113 -3 112 -3 22 -45
c27 -54 60 -127 205 -450 61 -135 126 -279 145 -320 19 -41 73 -160 120 -265
47 -104 90 -196 96 -202 7 -10 137 -13 587 -13 317 0 580 3 583 7 4 3 -39 94
-94 202 -122 236 -222 430 -316 611 -203 392 -333 648 -333 658 0 6 42 39 92
74 270 185 445 446 520 778 29 127 31 422 4 545 -54 247 -154 444 -311 615
-213 232 -434 360 -745 434 -122 29 -322 36 -1051 36 l-729 0 2 -1977z m1474
1048 c40 -5 97 -20 128 -34 76 -35 176 -135 213 -213 26 -55 28 -67 28 -189 0
-126 -1 -132 -32 -198 -59 -124 -179 -216 -309 -237 -32 -6 -164 -10 -291 -10
l-233 0 0 438 c0 241 3 442 7 445 9 10 404 8 489 -2z"/>
<path d="M14055 13848 c-3 -7 -6 -188 -7 -403 l-3 -390 -282 -3 -282 -2 -7
-38 c-36 -212 -124 -424 -248 -592 -60 -82 -190 -214 -268 -271 -37 -28 -68
-57 -68 -65 0 -8 7 -28 15 -44 l15 -30 563 -2 562 -3 3 -658 2 -657 523 2 522
3 0 1580 0 1580 -518 3 c-409 2 -519 0 -522 -10z"/>
<path d="M10624 10025 c-101 -22 -172 -61 -250 -139 -80 -80 -119 -148 -140
-245 -38 -181 11 -356 136 -481 68 -69 124 -101 218 -130 63 -19 90 -22 185
-18 124 6 173 20 249 75 l47 34 3 -48 3 -48 93 -3 92 -3 0 286 0 285 -250 0
-251 0 3 -97 3 -98 128 -3 c70 -1 127 -6 127 -11 0 -23 -74 -100 -121 -126
-49 -27 -62 -30 -149 -30 -107 0 -151 16 -215 80 -55 55 -78 109 -83 196 -6
109 15 168 88 241 45 45 69 60 115 74 111 32 251 -5 313 -82 l27 -34 123 0
c123 0 123 0 117 23 -16 52 -62 121 -115 171 -127 122 -313 171 -496 131z"/>
<path d="M11784 10025 c-172 -37 -324 -173 -374 -333 -60 -193 -14 -396 119
-530 105 -104 226 -152 387 -152 112 0 192 23 264 74 27 20 52 36 55 36 3 0 5
-22 5 -50 l0 -50 90 0 90 0 0 285 0 285 -250 0 -251 0 3 -97 3 -98 128 -3 c70
-1 127 -7 127 -12 0 -23 -71 -96 -120 -124 -51 -29 -62 -31 -150 -31 -114 0
-153 17 -223 95 -59 66 -80 128 -75 223 5 114 61 201 163 255 42 22 63 27 130
27 68 0 89 -4 138 -28 32 -16 70 -44 85 -63 l27 -34 123 0 c141 0 137 -5 83
94 -96 181 -348 281 -577 231z"/>
<path d="M14225 10018 c-3 -7 -4 -233 -3 -503 l3 -490 113 -3 112 -3 0 201 0
200 200 0 200 0 0 -200 0 -200 110 0 110 0 -2 503 -3 502 -105 0 -105 0 -3
-197 -2 -198 -200 0 -200 0 -2 198 -3 197 -108 3 c-80 2 -109 -1 -112 -10z"/>
<path d="M15260 9685 c0 -324 1 -349 21 -408 37 -110 103 -182 217 -235 52
-25 68 -27 177 -27 110 0 125 2 178 27 81 38 168 124 203 201 l29 62 0 360 0
360 -107 3 -108 3 0 -318 c0 -188 -4 -333 -10 -355 -22 -78 -102 -137 -187
-138 -53 0 -90 14 -126 48 -62 58 -62 57 -67 422 l-5 335 -107 3 -108 3 0
-346z"/>
<path d="M16260 9525 l0 -506 248 3 c235 3 250 4 299 27 59 26 117 84 140 140
9 21 16 66 16 107 0 58 -5 83 -27 128 -25 50 -96 126 -119 126 -5 0 9 20 30
44 119 135 72 341 -95 413 -30 14 -82 18 -264 21 l-228 4 0 -507z m381 285
c29 -16 51 -67 44 -100 -4 -16 -20 -41 -37 -55 -26 -22 -39 -25 -104 -25 l-74
0 0 95 0 95 76 0 c41 0 84 -5 95 -10z m58 -396 c37 -31 48 -78 28 -126 -22
-52 -67 -69 -169 -66 l-83 3 -3 108 -3 107 100 0 c92 0 102 -2 130 -26z"/>
<path d="M8710 9521 l0 -501 110 0 110 0 0 175 0 175 35 0 35 0 87 -175 88
-175 127 0 c71 0 128 4 128 8 0 5 -44 89 -99 188 l-99 179 22 18 c11 9 38 31
58 47 20 16 49 56 65 87 24 49 28 70 28 138 0 55 -6 96 -19 130 -26 65 -103
147 -169 176 -49 23 -66 24 -279 27 l-228 3 0 -500z m394 285 c51 -21 76 -60
76 -118 0 -94 -45 -128 -170 -128 l-80 0 0 123 c0 68 3 127 7 130 12 12 133 7
167 -7z"/>
<path d="M9550 9520 l0 -500 290 0 290 0 0 105 0 105 -180 0 -180 0 0 95 0 95
150 0 150 0 0 105 0 105 -150 0 -151 0 3 93 3 92 173 3 172 2 0 100 0 100
-285 0 -285 0 0 -500z"/>
<path d="M12550 9920 l0 -99 58 -3 57 -3 3 -292 2 -293 -60 0 -60 0 0 -105 0
-105 230 0 230 0 0 105 0 105 -60 0 -60 0 2 293 3 292 58 3 57 3 0 99 0 100
-230 0 -230 0 0 -100z"/>
<path d="M13180 9520 l0 -500 285 0 285 0 0 105 0 105 -177 2 -178 3 -3 92 -3
92 153 3 153 3 3 102 3 102 -153 3 -153 3 0 90 0 90 178 3 177 2 0 100 0 100
-285 0 -285 0 0 -500z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
